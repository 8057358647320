import React from "react";
import FacebookIcon from "../../../Icons/FacebookIcon";
import GithubIcon from "../../../Icons/GithubIcon";
import LinkdinIcon from "../../../Icons/LinkdinIcon";
import MediumIcon from "../../../Icons/MediumIcon";
import TwitterIcon from "../../../Icons/TwitterIcon";
import Container from "../Container";
import classes from "./Footer.module.scss";

const Footer: React.FC = (): JSX.Element => (
  <div className={`${classes.footer}`}>
    <Container classname={`${classes.footer_separates}`}>
      <p className={`${classes.copytights}`}>
        &copy; {new Date().getFullYear()} IntuCharge, Inc. All rights reserved.
      </p>
      <div className={`${classes.footer_icon_separates}`}>
        <span className="cursor-pointer" title="Facebook">
          <FacebookIcon />
        </span>
        <span className="cursor-pointer" title="LinkdIn">
          <LinkdinIcon />
        </span>
        <span className="cursor-pointer" title="GitHub">
          <GithubIcon />
        </span>
        <span className="cursor-pointer" title="Twitter">
          <TwitterIcon />
        </span>
        <span className="cursor-pointer" title="Medium">
          <MediumIcon />
        </span>
      </div>
    </Container>
  </div>
);

export default Footer;
