/* eslint-disable max-len */
import React from "react";

const GithubIcon: React.FC = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.99996 0C6.86291 9.08602e-05 4.79561 0.77984 3.16797 2.19973C1.54033 3.61961 0.458572 5.58698 0.116244 7.74981C-0.226085 9.91264 0.193359 12.1298 1.29952 14.0045C2.40569 15.8793 4.12639 17.2893 6.15375 17.9823C6.60375 18.0631 6.77249 17.7863 6.77249 17.544C6.77249 17.3249 6.76124 16.5982 6.76124 15.8254C4.49999 16.2522 3.915 15.2602 3.735 14.7412C3.53527 14.2363 3.21865 13.789 2.8125 13.4377C2.49751 13.2647 2.04751 12.8379 2.80125 12.8264C3.08905 12.8584 3.36509 12.9611 3.60597 13.1258C3.84684 13.2904 4.04546 13.5122 4.185 13.7722C4.30809 13.999 4.47361 14.1985 4.67206 14.3596C4.87052 14.5206 5.09802 14.6399 5.34152 14.7106C5.58502 14.7813 5.83973 14.8021 6.09107 14.7717C6.34241 14.7413 6.58543 14.6604 6.80621 14.5335C6.84517 14.0644 7.04908 13.6258 7.37997 13.2993C5.37749 13.0687 3.285 12.2727 3.285 8.74321C3.27235 7.82611 3.60242 6.93882 4.2075 6.26328C3.93235 5.46622 3.96454 4.5915 4.29749 3.81796C4.29749 3.81796 5.05121 3.57572 6.77248 4.76379C8.24512 4.34852 9.7998 4.34852 11.2724 4.76379C12.9937 3.5642 13.7474 3.81796 13.7474 3.81796C14.0804 4.59149 14.1126 5.46622 13.8374 6.26328C14.4443 6.93766 14.7747 7.82573 14.7599 8.74321C14.7599 12.2843 12.6562 13.0687 10.6537 13.2993C10.8685 13.5226 11.0339 13.7905 11.1387 14.0851C11.2435 14.3796 11.2853 14.6939 11.2612 15.0065C11.2612 16.2407 11.2499 17.2326 11.2499 17.5441C11.2499 17.7863 11.4187 18.0746 11.8687 17.9824C13.8925 17.2837 15.6082 15.8704 16.7097 13.9947C17.8111 12.1189 18.2266 9.90293 17.8819 7.7422C17.5372 5.58147 16.4548 3.61665 14.8278 2.19849C13.2009 0.780335 11.1354 0.00113953 8.99996 0Z"
      fill="black"
    />
  </svg>
);

export default GithubIcon;
