/* eslint-disable max-len */
import React from "react";

const LinkdinIcon: React.FC = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.669 0.000125846H1.33099C1.15857 -0.00225428 0.987375 0.0291482 0.827172 0.09254C0.666969 0.155932 0.520898 0.250071 0.397301 0.369581C0.273705 0.489092 0.175003 0.631633 0.106834 0.789063C0.0386641 0.946494 0.00236172 1.11573 0 1.28711V16.7129C0.00236172 16.8843 0.0386641 17.0535 0.106834 17.2109C0.175003 17.3684 0.273705 17.5109 0.397301 17.6304C0.520898 17.7499 0.666969 17.8441 0.827172 17.9075C0.987375 17.9708 1.15857 18.0023 1.33099 17.9999H16.669C16.8414 18.0023 17.0126 17.9708 17.1728 17.9075C17.333 17.8441 17.4791 17.7499 17.6027 17.6304C17.7263 17.5109 17.825 17.3684 17.8932 17.2109C17.9613 17.0535 17.9976 16.8843 18 16.7129V1.28711C17.9976 1.11573 17.9613 0.946494 17.8932 0.789063C17.825 0.631633 17.7263 0.489092 17.6027 0.369581C17.4791 0.250071 17.333 0.155932 17.1728 0.09254C17.0126 0.0291482 16.8414 -0.00225428 16.669 0.000125846ZM5.45976 15.0659H2.74346V6.96603H5.45976V15.0659ZM4.10161 5.83204C3.727 5.83204 3.36773 5.68412 3.10284 5.42083C2.83795 5.15753 2.68913 4.80042 2.68913 4.42806C2.68913 4.05571 2.83795 3.6986 3.10284 3.4353C3.36773 3.172 3.727 3.02408 4.10161 3.02408C4.30053 3.00166 4.50197 3.02125 4.69274 3.08158C4.88352 3.1419 5.05932 3.2416 5.20864 3.37414C5.35796 3.50669 5.47744 3.66909 5.55924 3.85071C5.64104 4.03233 5.68333 4.22907 5.68333 4.42806C5.68333 4.62705 5.64104 4.8238 5.55924 5.00542C5.47744 5.18704 5.35796 5.34944 5.20864 5.48198C5.05932 5.61453 4.88352 5.71422 4.69274 5.77455C4.50197 5.83488 4.30053 5.85447 4.10161 5.83204ZM15.2565 15.0659H12.5402V10.719C12.5402 9.62999 12.1509 8.919 11.164 8.919C10.8585 8.92122 10.5611 9.01645 10.3118 9.19185C10.0625 9.36725 9.87328 9.6144 9.76962 9.89999C9.69876 10.1115 9.66806 10.3343 9.67907 10.557V15.0569H6.96278C6.96278 15.0569 6.96278 7.69502 6.96278 6.95703H9.67907V8.10001C9.92583 7.67441 10.2847 7.32379 10.7171 7.08591C11.1495 6.84803 11.6389 6.7319 12.1328 6.75003C13.9437 6.75003 15.2565 7.91102 15.2565 10.404V15.0659Z"
      fill="black"
    />
  </svg>
);

export default LinkdinIcon;
