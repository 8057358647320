import React from "react";
import Footer from "../components/CommonSection/LandingPageFooter/Footer";
import Header from "../components/CommonSection/LandingPageHeader/Header";
interface PropsTypes {
  children: JSX.Element;
}
const LandingPageLayout: React.FC<PropsTypes> = (props): JSX.Element => (
  <>
    <Header />
    {props.children}
    <Footer />
  </>
);

export default LandingPageLayout;
