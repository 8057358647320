/* eslint-disable max-len */
import React from "react";

const TwitterIcon: React.FC = () => (
  <svg width="18" height="18" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 1.67729C17.3235 1.9577 16.6081 2.14306 15.876 2.22762C16.6484 1.78678 17.2271 1.09324 17.505 0.27568C16.7792 0.688479 15.9847 0.979322 15.156 1.13557C14.6021 0.561654 13.8645 0.179627 13.0588 0.0494019C12.2532 -0.0808235 11.4251 0.0481181 10.7044 0.416004C9.98373 0.78389 9.41124 1.36991 9.07672 2.08216C8.74219 2.79441 8.66456 3.5926 8.856 4.35154C7.38848 4.28062 5.953 3.91554 4.64279 3.28C3.33258 2.64447 2.17696 1.7527 1.251 0.662628C0.926224 1.2045 0.755567 1.81816 0.756 2.44259C0.754848 3.02248 0.903795 3.59365 1.18958 4.10524C1.47536 4.61683 1.88912 5.05296 2.394 5.3748C1.80718 5.35955 1.2329 5.2091 0.72 4.93626V4.97926C0.724398 5.79176 1.02239 6.57786 1.56358 7.20461C2.10477 7.83136 2.85593 8.26027 3.69 8.4188C3.36893 8.51216 3.03559 8.56138 2.7 8.56498C2.4677 8.56239 2.23598 8.54226 2.007 8.50479C2.24452 9.20372 2.70416 9.81452 3.32197 10.2522C3.93977 10.6899 4.68502 10.9327 5.454 10.9469C4.15548 11.9231 2.55229 12.4558 0.9 12.4603C0.599162 12.4612 0.298562 12.444 0 12.4087C1.68699 13.4494 3.65293 14.0018 5.661 13.9995C7.04672 14.0132 8.42142 13.763 9.7048 13.2635C10.9882 12.7639 12.1545 12.0251 13.1357 11.09C14.1168 10.155 14.8932 9.04255 15.4193 7.81766C15.9454 6.59277 16.2108 5.27998 16.2 3.95599C16.2 3.80981 16.2 3.65503 16.2 3.50025C16.9062 2.99706 17.5153 2.38019 18 1.67729Z"
      fill="black"
    />
  </svg>
);

export default TwitterIcon;
