import { useRouter } from "next/router";
import React from "react";
import Container from "../Container";
import classes from "./Header.module.scss";
import HeaderLogo from "./HeaderLogo";

const Header: React.FC = (): JSX.Element => {
  const router = useRouter();
  return (
    <div className={`${classes.header}`}>
      <Container classname={`${classes.header_separates}`}>
        <div className={`${classes.header_logo}`} title="IntuCharge ">
          <HeaderLogo />
        </div>

        <div className={`${classes.menu_separates}`}>
          <div
            role="button"
            aria-hidden="true"
            className={`${classes.menu_link} text-font-textcolor1`}
            onClick={() => {
              router.push("/about-us");
            }}
            title="AboutUs"
          >
            About Us
          </div>

          <div
            role="button"
            aria-hidden="true"
            className={`${classes.menu_link} text-primary-main`}
            onClick={() => {
              router.push("/lets-connect");
            }}
            title="LetConnect"
          >
            Let&apos;s Connect
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Header;
