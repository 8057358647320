/* eslint-disable max-len */

export default function HeaderLogo() {
  return (
    <svg
      width="184"
      height="46"
      viewBox="0 0 184 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.5275 41.5348C29.6546 41.5348 25.8583 39.896 23.1386 36.6184C20.4188 33.3409 19.059 28.2926 19.059 21.4738C19.059 14.6172 20.4188 9.56901 23.1386 6.32911C25.8583 3.05154 29.6546 1.41275 34.5275 1.41275C37.2472 1.41275 39.6836 1.94018 41.8368 2.99503C43.9899 4.04988 45.6897 5.68867 46.9363 7.91139C48.1828 10.1341 48.8061 12.9973 48.8061 16.5009H41.3835C41.3835 13.487 40.7413 11.2643 39.457 9.83273C38.2105 8.36347 36.5673 7.62884 34.5275 7.62884C31.8455 7.62884 29.8435 8.7402 28.5214 10.9629C27.1993 13.148 26.5382 16.1807 26.5382 20.061V22.8865C26.5382 26.7669 27.2182 29.8184 28.578 32.0411C29.9379 34.2262 31.9966 35.3187 34.7541 35.3187C36.8695 35.3187 38.5504 34.6029 39.797 33.1713C41.0813 31.7021 41.7235 29.4793 41.7235 26.5032H48.8061C48.8061 29.8938 48.1828 32.7004 46.9363 34.9231C45.6897 37.1459 43.9899 38.8035 41.8368 39.896C39.7214 40.9885 37.285 41.5348 34.5275 41.5348Z"
        fill="#060F17"
      />
      <path
        d="M55.3698 40.8567V0H62.1691V14.297H62.3958C63.0379 13.3552 63.9634 12.4887 65.1722 11.6976C66.3809 10.8687 67.8541 10.4543 69.5917 10.4543C71.896 10.4543 73.7847 11.1701 75.2579 12.6017C76.7688 14.0333 77.5243 16.5009 77.5243 20.0045V40.8567H70.725V21.8693C70.725 19.948 70.3661 18.5918 69.6484 17.8006C68.9685 17.0095 68.0241 16.6139 66.8153 16.6139C65.4932 16.6139 64.3789 17.1979 63.4723 18.3657C62.6035 19.4959 62.1691 20.8898 62.1691 22.5475V40.8567H55.3698Z"
        fill="#060F17"
      />
      <path
        d="M90.5009 41.5348C89.8209 41.5348 89.0466 41.4218 88.1778 41.1957C87.309 40.9697 86.4779 40.5553 85.6847 39.9525C84.9292 39.3498 84.287 38.5021 83.7582 37.4096C83.2671 36.3171 83.0216 34.9043 83.0216 33.1713C83.0216 30.4965 83.6449 28.4057 84.8914 26.8987C86.1757 25.3918 87.9322 24.3369 90.1609 23.7342C92.3896 23.0937 94.9393 22.7735 97.8102 22.7735V19.6655C97.8102 18.6483 97.6213 17.7818 97.2436 17.066C96.8658 16.3502 96.0159 15.9923 94.6938 15.9923C93.2962 15.9923 92.3518 16.3314 91.8607 17.0095C91.3697 17.6876 91.1241 18.3846 91.1241 19.1004V19.948H84.4948C84.457 19.7596 84.4381 19.5713 84.4381 19.3829C84.4381 19.1945 84.4381 18.9685 84.4381 18.7048C84.4381 16.2183 85.3447 14.2217 87.1579 12.7147C89.0088 11.2078 91.4641 10.4543 94.5238 10.4543C97.999 10.4543 100.549 11.2266 102.173 12.7712C103.835 14.2782 104.666 16.4256 104.666 19.2134V33.8494C104.666 34.5652 104.836 35.0738 105.176 35.3752C105.516 35.6766 105.875 35.8273 106.253 35.8273H107.613V40.6306C107.235 40.819 106.725 41.0074 106.083 41.1957C105.441 41.4218 104.61 41.5348 103.59 41.5348C102.23 41.5348 101.097 41.1769 100.19 40.4611C99.2834 39.7453 98.6601 38.8035 98.3201 37.6356H98.0368C97.2058 38.8035 96.2425 39.7453 95.1471 40.4611C94.0516 41.1769 92.5029 41.5348 90.5009 41.5348ZM93.3339 35.8838C94.4294 35.8838 95.2982 35.5824 95.9403 34.9796C96.5825 34.3769 97.0547 33.5857 97.3569 32.6062C97.6591 31.6267 97.8102 30.553 97.8102 29.3852V27.6899C96.4881 27.6899 95.2226 27.8406 94.0139 28.1419C92.8429 28.4057 91.8796 28.8954 91.1241 29.6112C90.3687 30.2893 89.9909 31.2688 89.9909 32.5497C89.9909 33.5669 90.2553 34.3769 90.7842 34.9796C91.313 35.5824 92.1629 35.8838 93.3339 35.8838Z"
        fill="#060F17"
      />
      <path
        d="M112.774 40.8567V11.1325H118.44L119.234 15.4837H119.574C119.989 14.2405 120.575 13.0915 121.33 12.0366C122.086 10.9818 123.219 10.4543 124.73 10.4543C125.599 10.4543 126.279 10.5297 126.77 10.6804C127.298 10.8311 127.563 10.9064 127.563 10.9064V17.4616H125.126C124.144 17.4616 123.219 17.6688 122.35 18.0832C121.519 18.4976 120.839 19.1945 120.31 20.174C119.819 21.1536 119.574 22.5286 119.574 24.2993V40.8567H112.774Z"
        fill="#060F17"
      />
      <path
        d="M172.745 41.5348C168.929 41.5348 166.021 40.2916 164.019 37.8051C162.054 35.281 161.072 31.3442 161.072 25.9946C161.072 20.645 162.054 16.7269 164.019 14.2405C166.021 11.7164 168.854 10.4543 172.518 10.4543C176.106 10.4543 178.788 11.7164 180.564 14.2405C182.377 16.7269 183.284 20.645 183.284 25.9946V27.6899H168.155C168.193 30.327 168.552 32.3802 169.232 33.8494C169.911 35.281 171.082 35.9968 172.745 35.9968C173.915 35.9968 174.841 35.5071 175.521 34.5276C176.201 33.5104 176.541 32.1165 176.541 30.3458H183.284C183.284 34.1508 182.283 36.9763 180.28 38.8223C178.278 40.6306 175.766 41.5348 172.745 41.5348ZM168.212 22.7735H176.144C176.144 20.6261 175.842 18.9685 175.238 17.8006C174.671 16.5951 173.764 15.9923 172.518 15.9923C171.082 15.9923 170.025 16.5951 169.345 17.8006C168.703 18.9685 168.325 20.6261 168.212 22.7735Z"
        fill="#060F17"
      />
      <path
        d="M0.455761 39.2944C0.167871 39.5825 0.0239258 39.9666 0.0239258 40.4347C0.0239258 40.9028 0.167871 41.2749 0.455761 41.563C0.743651 41.8511 1.11551 41.9951 1.58333 41.9951L14.8863 41.9951C15.3541 41.9951 15.7259 41.8511 16.0138 41.563C16.3017 41.2749 16.4457 40.9028 16.4457 40.4347C16.4457 39.9666 16.3017 39.5945 16.0138 39.2944C15.7259 39.0063 15.3541 38.8503 14.8863 38.8503L1.58333 38.8503C1.1275 38.8503 0.743651 38.9943 0.455761 39.2944Z"
        fill="#50C878"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4937 8.47643C16.4937 11.8974 13.8547 14.6821 10.604 14.6821L1.41546 14.6821C0.635757 14.6821 7.5863e-09 14.046 1.68903e-08 13.2657C2.61942e-08 12.4855 0.635757 11.8494 1.41546 11.8494L10.604 11.8494C12.2953 11.8494 13.6628 10.3369 13.6628 8.46443C13.6628 6.60392 12.2833 5.09151 10.604 5.09151L1.41546 5.09151C0.635757 5.09151 1.21953e-07 4.45533 1.31257e-07 3.67512C1.40561e-07 2.8949 0.635757 2.25873 1.41546 2.25873L10.604 2.25873C13.8547 2.25873 16.4937 5.04349 16.4937 8.47643Z"
        fill="#50C878"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5177 17.7432C16.5177 21.1641 13.8667 23.9849 10.604 23.9849L1.41546 23.9849C0.635757 23.9849 7.58629e-09 23.3487 1.68902e-08 22.5685C2.61942e-08 21.7883 0.635757 21.1521 1.41546 21.1521L3.1428 21.1521L3.1428 20.2879C3.1428 19.4236 3.85053 18.7154 4.7142 18.7154C5.57787 18.7154 6.2856 19.4236 6.2856 20.2879L6.2856 21.1521L10.604 21.1521C12.2713 21.1521 13.6868 19.5677 13.6868 17.7312C13.6868 17.251 13.5908 16.7949 13.4349 16.3748C14.3105 15.9787 15.1022 15.4025 15.762 14.6823C16.2418 15.5946 16.5177 16.6389 16.5177 17.7432Z"
        fill="#50C878"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.91368 25.2121L15.1022 25.2121C15.8819 25.2121 16.5176 25.8483 16.5176 26.6285C16.5176 27.4087 15.8819 28.0449 15.1022 28.0449L5.91368 28.0449C4.22232 28.0449 2.85485 29.5573 2.85485 31.4178C2.85485 33.2783 4.23432 34.7908 5.91368 34.7908L15.1022 34.7908C15.8819 34.7908 16.5176 35.4269 16.5176 36.2071C16.5176 36.9874 15.8819 37.6235 15.1022 37.6235L5.91368 37.6235C2.66292 37.6235 0.0239258 34.8388 0.0239259 31.4178C0.0239259 27.9969 2.67491 25.2121 5.91368 25.2121Z"
        fill="#50C878"
      />
      <path
        d="M138.635 39.0894C138.635 42.7405 141.595 45.7007 145.246 45.7007C148.897 45.7007 151.857 42.7405 151.857 39.0894C151.857 35.4384 148.897 32.4782 145.246 32.4782C141.595 32.4782 138.635 35.4384 138.635 39.0894Z"
        fill="#50C878"
      />
      <path
        d="M145.046 32.479C152.348 32.479 158.267 26.5586 158.267 19.2565H145.046L145.046 32.479Z"
        fill="#50C878"
      />
      <path
        d="M131.824 19.2565C131.824 26.5586 137.744 32.479 145.046 32.479L145.046 19.2565H131.824Z"
        fill="#50C878"
      />
      <path
        d="M145.046 19.2573H158.267C158.267 11.9553 152.348 6.03485 145.046 6.03485L145.046 19.2573Z"
        fill="#50C878"
      />
      <path
        d="M131.824 19.2573H145.046L145.046 6.03485C137.744 6.03485 131.824 11.9553 131.824 19.2573Z"
        fill="#50C878"
      />
      <path
        d="M149.557 18.0581L145.5 27.5244C145.437 27.6653 145.327 27.7804 145.19 27.8508C145.052 27.9212 144.895 27.9427 144.744 27.9117C144.592 27.8806 144.456 27.7989 144.358 27.6801C144.259 27.5613 144.204 27.4124 144.201 27.258V21.1725H140.821C140.709 21.1719 140.599 21.1438 140.501 21.0906C140.403 21.0374 140.319 20.9608 140.258 20.8676C140.196 20.7744 140.158 20.6674 140.148 20.5562C140.138 20.4449 140.155 20.3329 140.199 20.23L144.256 10.7637C144.317 10.6202 144.426 10.5023 144.564 10.4298C144.702 10.3574 144.861 10.335 145.014 10.3663C145.166 10.3977 145.304 10.4809 145.402 10.6019C145.5 10.7228 145.554 10.8741 145.554 11.0301V17.1156H148.935C149.046 17.1161 149.156 17.1443 149.254 17.1974C149.353 17.2506 149.436 17.3272 149.498 17.4205C149.559 17.5137 149.597 17.6207 149.607 17.7319C149.617 17.8431 149.6 17.9552 149.557 18.0581Z"
        fill="white"
      />
    </svg>
  );
}
